import React, {
    useState,
    useCallback
  } from 'react'  
import update from 'immutability-helper'
import {
    Card,
    TextField,
    FormLayout,
    Stack,
    Select,
    Button,
    Modal,
    ChoiceList,
    Thumbnail,
    Badge
  } from "@shopify/polaris" 
import styled from 'styled-components'

const FeaturedImageWrapper = styled.div`
  margin-top: 30px;
  .featued-image-title {
    display: block;
    padding-bottom: 5px;
    font-weight: 600;
  }
`
const options = [
  {label: 'Fill', value: 'fill'},
  {label: 'Contain', value: 'contain'},
  {label: 'Cover', value: 'cover'}
];

function FeaturedImage(props) {
  const { state, setState } = props;
  const [active, setActive] = useState(-1);
  const [selectedImage, setSelectedImage] = useState([]);
  const handleImageChange = useCallback((value) => setSelectedImage(value), []);
  const activator = (index) => {
    return <Button onClick={ () => setActive(index)}>Change image</Button>
  }
  const updateFeaturedImage = (index) => {
        // console.log(selectedImage)
        if(selectedImage.length > 0){
          // setState(update(state, { featuredImage: { $set: selectedImage[0] }}))
          // let index = 0
          let val = selectedImage[0]
          setState(update(state, { product: { [index]:{featuredImage: {$set: val }}}}))
        }
        setActive(-1)
  }

  function isEmpty(obj) {
      for(var prop in obj) {
          if(obj.hasOwnProperty(prop))
              return false;
      }
  
      return true;
  }
     
    return (
        <FormLayout>
          {state.product.length && !isEmpty(state.product[0]) ?
          <FeaturedImageWrapper>
                    <span className='featued-image-title'>Featured image</span>
                    <Card>
                      <Card.Section>
                        <Stack distribution="fill" alignment="center">
                          <Stack vertical spacing="tight">
                      {state.product.map((p,index) => {
                         
                         return <div key={index}>
                        Product {index+1}
                        {!isEmpty(state.product[index]) ?
                     <div>
                          <img 
                            style={{
                              width: 60,
                              height: 60,
                              objectFit: "contain",
                              objectPosition: "center",
                              border: '1px solid #AEB4B9',
                              borderRadius: '5px'
                            }}
                            src={state.product[index].featuredImage ? state.product[index].featuredImage : (state.product[index].product?.image || 'https://via.placeholder.com/200')}
                          />
                       
                        {
                            state.product && !state.shopBrain &&
                            <Modal
                            activator={activator(index)}
                            open={index === active}
                            onClose={ () => setActive(-1)}
                            title="Images"
                            primaryAction={{
                              content: 'Add image',
                              onAction: () => updateFeaturedImage(index),
                            }}
                            secondaryActions={[
                              {
                                content: 'Cancel',
                                onAction: () => setActive(-1),
                              },
                            ]}
                          >
                            <Modal.Section>
                             
                            <ChoiceList
                              choices={state.product[index].product?.images ? state.product[index].product.images.map(image =>
                                { 
                                  return {
                                   label: 
                                   <Thumbnail
                                     source={image.originalSrc}
                                     />, 
                                   value: image.originalSrc}
                                 }): [{label: 
                                  <Thumbnail
                                    source={state.product[index].product?.image}
                                    />, 
                                  value: state.product[index].product?.image}]}
                              selected={selectedImage}
                              onChange={handleImageChange}
                            />
                             
                            </Modal.Section>
                          </Modal>
                          }
                      </div>:
                      <div style={{padding:'5px 0', maxWidth:'185px', textAlign:'center'}}>
                      <Badge status="attention">Choose a product to see image options</Badge>
                      </div>
                      }
                        </div>
                      })
                      }
                      </Stack>
              <FormLayout>
                <Select
                  label="Image Fits"
                  name='imagePosition'
                  options={options}
                  onChange={(val)=>{
                    setState(update(props.state, { imagePosition: { $set: val }}))
                  }}
                  value={props.state.imagePosition || 'cover'}
                />
                {"tiles" !== state.generalLayout &&
                <TextField
                  label="Width"
                  name='imageWidthHeight'
                  type="number"
                  value={props.state.imageWidthHeight}
                  onChange={(val)=>{
                    setState(update(props.state, { imageWidthHeight: { $set: val }}))
                  }}
                  autoComplete="off"
                  suffix="px"
                />
                }
                <TextField
                  label="Width / height aspect ratio"
                  name='imagePadding'
                  type="text"
                  value={props.state.imagePadding}
                  onChange={(val)=>{
                    setState(update(props.state, { imagePadding: { $set: val }}))
                  }}
                  autoComplete="off"
                />
              </FormLayout>
        </Stack>
                      </Card.Section>
                    </Card>
                  </FeaturedImageWrapper>
                  :
                  false}
        </FormLayout>
    )
}

export default FeaturedImage;